import './App.css';
import video from './video/video1.mp4';
import useVideoPlayer from './useVideoPlayer';
import React, { useRef, useState, useEffect } from 'react';
import { ReactComponent as Mute } from './images/mute.svg';
import { ReactComponent as UnMute } from './images/unMute.svg';
import { ReactComponent as Play } from './images/play.svg';
import { ReactComponent as Pause } from './images/pause.svg';
import { Loader } from './Loader/Loader.jsx';

function English() {
  const videoElement = useRef(null);

  const { playerState, togglePlay, toggleMute } = useVideoPlayer(videoElement);

  const [disable, setDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const playFirstTime = () => {
    togglePlay();
    setDisable(true);
  };

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <div>
      {isLoading && <Loader />}

      <div className="container">
        <div className="shadow">
          <div className={!disable ? 'video-wrapper-blur' : 'video-wrapper'}>
            <video src={video} ref={videoElement} controls="false" />
          </div>
          <button className={disable ? 'hide' : ''} onClick={playFirstTime}>
            {!playerState.isPlaying ? <i className="bx bx-play">View invitation</i> : <i className="hide bx bx-pause">hide</i>}
          </button>
        </div>
        <div className="controls">
          <div className={!disable ? 'hide' : 'actions'}>
            <div className="play-btn" onClick={togglePlay}>
              {!playerState.isPlaying ? (
                <Play />
              ) : (
                <i className="">
                  <Pause />
                </i>
              )}
            </div>
            <div className="mute-btn" onClick={toggleMute}>
              {!playerState.isMuted ? <Mute /> : <UnMute />}
            </div>
          </div>
        </div>
        <div className={!disable ? 'hide' : 'location'}>
          <a href="https://goo.gl/maps/x4VnWGVd7U1ivR1S6" target="_blank" rel="noreferrer">
            Garden Paradiso
          </a>
        </div>
      </div>
    </div>
  );
}

export default English;
