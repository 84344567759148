import {
  BrowserRouter as Router,
  Switch,
  Route,
  // Link
} from "react-router-dom";
import English from "./English.jsx"
import Church from "./Church.jsx"


export default function App() {
  return (
    <Router>
      <div className="App">
        {/* <nav>
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/ar">Arabic</Link>
            </li>
       
          </ul>
        </nav> */}

        <Switch>
        <Route exact path="/" component={English} />
        <Route exact path="/wedding" component={Church} />
          
        </Switch>
      </div>
    </Router>
  );
}